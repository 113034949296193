<script>
	import Vue from 'vue';
	import GlobalVue from '../helper/Global.vue'
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				input:{},
				cmp_name:"",
				cmp_address:"",
				cmp_number:"",
				cmp_email:"",
				open_service:"",
				contacts:{},
			}
		},
		computed:{
			mrValidation(){
				return this.$root.config.mrValidation.contact_us
			},
			breadCrumb(){
				return [{
					name: 'Home',
					link: { name: 'index' }
				},{
					name: this.web.lbl_talk_to_us,
					link: { name: 'ContactUs' }
				}]
			}
		},
		async mounted() {
			await this.ready();
			this.refreshMeta()
			this.$set(this.$root, 'page', this);
			if (typeof SEMICOLON != "undefined") SEMICOLON.documentOnReady.init()
			setTimeout(() => {
				$('[data-toggle="tooltip"]').tooltip();
				SEMICOLON.documentOnReady.init()
				SEMICOLON.slider.sliderParallax()
				SEMICOLON.widget.carousel()
			}, 100)
			$(document).ready(() => {
				AOS.init()
			})

			$(document).ready(function () {
				$('[data-toggle="popover"]').popover({
					trigger: 'hover',
					html: true,
					content: function () {
						return '<img class="img-fluid" src="' + $(this).data('img') + '" />';
					},
					title: 'Toolbox'
				})
				$("label[error]").hide()
				$("form").submit(function () {
					$("form label[error]").show();
				});
			});
			$(document).on('click', '.mfp-close', () => {
				this.$router.push('/')
			});
			this.webSettings()
			this.masterSettings()
			this.contacsApi()
		},
		methods: {
			onSubmit(e) {
				if(e&&e.btnLoading()) return;
				Gen.apirest("/submit-contact",this.input,(err,resp)=>{
					if(e) e.btnUnloading()
					if(err) return swal(err.resp.message,"","warning")
					this.input = {}
					swal(resp.message,"","success")
					this.$router.push({
						name:'index'
					})
				},"POST")
			},
			masterSettings(){
				Gen.apirest("/static-content", {id:11}, (err,resp)=>{
					this.$root.topProgress.done()
					if(err) console.log(err)
					this.open_service = resp.data
				})
			},
			webSettings(){
				this.$root.topProgress.done()
				Gen.apirest("/web-setting", {}, (err,resp)=>{
					if(err) console.log(err)
					this.cmp_name = resp.data.cmp_name
					this.cmp_address = resp.data.cmp_address
					this.cmp_number = resp.data.cmp_number
					this.cmp_email = resp.data.cmp_email
				})
			},
			contacsApi(){
				Gen.apirest("/get-contact", {}, (err,resp)=>{
					if(err) console.log(err)
					this.contacts = resp.data
				})
			}
		},
		watch: {}
	};
</script>
<template>
	<div id="content">
		<section class="section nobottommargin notopmargin wall-bg">
			<div class="container">
				<BreadCrumb :data="breadCrumb"></BreadCrumb>
				<div class="heading-title">
					<h2>{{web.lbl_talk_to_us}}</h2>
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="contact-form">
							<h2 class="motto">{{web.lbl_weare_here_to_assist}}</h2>
							<p class="text-center" v-html="open_service.description"></p>
							<div class="contact-list">
								<div class="cl-item" v-for="(v,k) in contacts" :key="k">
									<h4>{{v.type == 'W' ? 'Whatsapp':'Email'}}</h4>
									<a v-if="v.type == 'W'" :href="'https://wa.me/62'+v.contacts+'?text=Halo,%20Saya%20ingin%20memesan%20paket%20berlangganan%20dari%20Cape%20Discovery%20-%20Bellissimo'"
										target="_blank"><span>+62{{v.contacts}}</span></a>
									<span v-else>{{v.contacts}}</span>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="contact-form">
							<VForm @resp="onSubmit">
								<div class="form-group">
									<label for="">{{web.lbl_name}}<span class="text-red txt-req">*</span></label>
									<input type="text" name="fullname" onkeydown="return fullNameKey(event)" v-model="input.fullname" class="form-control" v-bind="validation('fullname')">
									<LabelError name="fullname"></LabelError>
								</div>
								<div class="form-group">
									<label for="">{{web.lbl_email}}<span class="text-red txt-req">*</span></label>
									<input type="email" name="email" onkeydown="return emailKey(event)" v-model="input.email" class="form-control" v-bind="validation('email')">
									<LabelError name="email"></LabelError>
								</div>
								<div class="form-group">
									<label for="">{{web.lbl_phone}}</label>
									<InputNumber type="PHONE" v-model="input.phone" class="form-control"></InputNumber>
								</div>
								<div class="form-group">
									<label for="">{{web.lbl_ask_us_anything}}<span class="text-red txt-req">*</span></label>
									<textarea name="message" v-bind="validation('message')" v-model="input.message" id="" cols="30" rows="10" class="form-control"></textarea>
									<LabelError name="message"></LabelError>
								</div>
								<div class="form-group">
									<button type="submit" class="button button-red btn-loading">{{web.btn_submit}}</button>
								</div>
							</VForm>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>